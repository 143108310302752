

import Swiper from "swiper";
export default {
    components: {

    },
    data() {
        return {
            // elmentList: [
            //     {
            //         pcImg: require('./images/pc/banner/banner1.jpg'),
            //         phoneImg: require('./images/mobile/banner/banner1.jpg'),
            //         decList: [{
            //             decName: '感谢您选购并使用七腾机器人产品，我司将持续为您提供高品质的售后服务。'
            //         }, {
            //             decName: '24小时售后热线：400-8765-700'
            //         }]
            //     },
            //     {
            //         pcImg: require('./images/pc/banner/banner2.jpg'),
            //         phoneImg: require('./images/mobile/banner/banner2.jpg'),
            //         decList: [{
            //             decName: '概览'
            //         }, {
            //             decName: '服务内容'
            //         },]
            //     },
            //     {
            //         pcImg: require('./images/pc/banner/banner3.jpg'),
            //         phoneImg: require('./images/mobile/banner/banner3.jpg'),
            //         decList: [{
            //             decName: '产品培训服务'
            //         }, {
            //             decName: '产品正式交付前，七腾机器人向用户提供为期一天的标准产品使用培训、安全知识培训和售后知识培训，以确保用户能够掌握产品的安全使用方法及售后服务内容。'
            //         }, {
            //             decName: '预约培训'
            //         }]
            //     },
            //     {
            //         pcImg: require('./images/pc/banner/banner4.jpg'),
            //         phoneImg: require('./images/mobile/banner/banner4.jpg'),
            //         decList: [{
            //             decName: '保内售后服务'
            //         }, {
            //             decName: '产品正式交付之日起，整机及配件默认免费质保1年，软件享有免费标准化升级。在质保期内，每3~6个月派遣专业的售后服务工程师现场对产品进行保养。实际质保期限或维保周期及易损件更换内容以合同约定为准。'
            //         }, {
            //             decName: '预约服务'
            //         }]
            //     },
            //     {
            //         pcImg: require('./images/pc/banner/banner5.jpg'),
            //         phoneImg: require('./images/mobile/banner/banner5.jpg'),
            //         decList: [{
            //             decName: '保外有偿售后服务'
            //         }, {
            //             decName: '保内售后服务期限满后，七腾机器人可继续为客户提供售后服务，具体费用及服务形式以合同约定为准。'
            //         }, {
            //             decName: '预约服务'
            //         }]
            //     },
            //     {
            //         pcImg: require('./images/pc/banner/banner6.jpg'),
            //         phoneImg: require('./images/mobile/banner/banner6.jpg'),
            //         decList: [{
            //             decName: '产品定制服务'
            //         }, {
            //             decName: '根据客户的使用需求，针对产品软、硬件进行定制开发或改造，根据具体工作量收取定制费用。'
            //         }, {
            //             decName: '立即定制'
            //         }]
            //     },

            //     {
            //         pcImg: require('./images/pc/maintenance/1.jpg'),
            //         phoneImg: require('./images/mobile/maintenance/1.jpg'),
            //         decList: [{
            //             decName: '外观清洁'
            //         }, {
            //             decName: '常规保养内容'
            //         }, {
            //             decName: '建议客户按以下保养内容对产品进行定期保养，保养能够提升产品的稳定性及延长产品使用寿命。保养过程中请确保机器人已经处于关机状态。'
            //         },]
            //     },
            //     {
            //         pcImg: require('./images/pc/maintenance/2.jpg'),
            //         phoneImg: require('./images/mobile/maintenance/2.jpg'),
            //         decList: [{
            //             decName: '激光雷达'
            //         }, {
            //             decName: '常规保养内容'
            //         }, {
            //             decName: '建议客户按以下保养内容对产品进行定期保养，保养工作能够提升产品的稳定性及延长产品使用寿命。保养过程中请确保机器人已经处于关机状态。'
            //         },]
            //     },
            //     {
            //         pcImg: require('./images/pc/maintenance/3.jpg'),
            //         phoneImg: require('./images/mobile/maintenance/3.jpg'),
            //         decList: [{
            //             decName: '轮胎'
            //         }, {
            //             decName: '常规保养内容'
            //         }, {
            //             decName: '建议客户按以下保养内容对产品进行定期保养，保养工作能够提升产品的稳定性及延长产品使用寿命。保养过程中请确保机器人已经处于关机状态。'
            //         },]
            //     },
            //     {
            //         pcImg: require('./images/pc/maintenance/4.jpg'),
            //         phoneImg: require('./images/mobile/maintenance/4.jpg'),
            //         decList: [{
            //             decName: '云台'
            //         }, {
            //             decName: '常规保养内容'
            //         }, {
            //             decName: '建议客户按以下保养内容对产品进行定期保养，保养工作能够提升产品的稳定性及延长产品使用寿命。保养过程中请确保机器人已经处于关机状态。'
            //         },]
            //     },
            //     {
            //         pcImg: require('./images/pc/maintenance/5.jpg'),
            //         phoneImg: require('./images/mobile/maintenance/5.jpg'),
            //         decList: [{
            //             decName: '充电桩'
            //         }, {
            //             decName: '常规保养内容'
            //         }, {
            //             decName: '建议客户按以下保养内容对产品进行定期保养，保养工作能够提升产品的稳定性及延长产品使用寿命。保养过程中请确保机器人已经处于关机状态。'
            //         },]
            //     },
            //     {
            //         pcImg: require('./images/pc/maintenance/6.jpg'),
            //         phoneImg: require('./images/mobile/maintenance/6.jpg'),
            //         decList: [{
            //             decName: '充电房'
            //         }, {
            //             decName: '常规保养内容'
            //         }, {
            //             decName: '建议客户按以下保养内容对产品进行定期保养，保养工作能够提升产品的稳定性及延长产品使用寿命。保养过程中请确保机器人已经处于关机状态。'
            //         },]
            //     },
            // ],

            // serviceList: [
            //     {
            //         img: require('./images/pc/service1.jpg'),
            //         phoneImg: require('./images/mobile/service1.jpg'),
            //         name: '产品培训服务'
            //     },
            //     {
            //         img: require('./images/pc/service2.jpg'),
            //         phoneImg: require('./images/mobile/service2.jpg'),
            //         name: '保内售后服务'
            //     },
            //     {
            //         img: require('./images/pc/service3.jpg'),
            //         phoneImg: require('./images/mobile/service3.jpg'),
            //         name: '保外有偿售后服务'
            //     },
            //     {
            //         img: require('./images/pc/service4.jpg'),
            //         phoneImg: require('./images/mobile/service4.jpg'),
            //         name: '产品定制服务'
            //     },
            // ],
            elmentList:[],
            aboutswiper: null,
        }
    },
    created() {
          // 获取当前页面的配置信息
          this.$store.dispatch('pageset/getPageInfo', this.$route.query.pageId).then(() => {
            this.elmentList = this.$store.state.pageset.sourceData.elmentList;
            setTimeout(() => {
                this.aboutSwiper()
            }, 100);
        });
    },
    // 销毁滚轮事件
    destroyed() {
        // 销毁swiper
        this.aboutswiper.destroy();
    },
    methods: {
        retention() {
            this.$router.push('/reservation');
        },
        // 初始化轮播图
        aboutSwiper() {
            let that = this;
            this.aboutswiper = new Swiper('.mySwiper', {
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    // type:'custom'
                    renderBullet: function (index, className) {
                        if (that.$store.state.pageset.pcshow) {
                            return '<span class="' + className + '">' + (that.elmentList[index + 6].descList[0].descName) + '</span>';
                        } else {
                            return `<span class="${className}"> </span>`;
                        }
                    },
                },
                autoplay: true,
                speed: 2000,
                loop: true,
                effect: 'fade',//切换效果
                fadeEffect: {
                    crossFade: true  //开启淡出。过渡时，原slide透明度从1->0（淡出），过渡中的slide透明度从0->1（淡入），其他slide透明度0。
                    //false  关闭淡出。过渡时，原slide透明度为1（不淡出），过渡中的slide透明度从0->1（淡入），其他slide透明度0。
                },
            });
        },
        servicePDF() {
            window.open(this.elmentList[16].pcImg);
        }
    }
}