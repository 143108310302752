<template>
  <div class="service" v-if="elmentList.length">
    <div class="service-content">
      <div class="content-one"  :id="'elm' + elmentList[0].id">
        <img :src="elmentList[0].pcImg" v-if="this.$store.state.pageset.pcshow" />
        <img :src="elmentList[0].phoneImg" v-else />
        <div class="dec" v-animate="'queue-bottom'">
          <p class="p1">{{ elmentList[0].descList[0].descName }}</p>
          <p class="p2">{{ elmentList[0].descList[1].descName }}</p>
        </div>
      </div>

      <div class="content-two">
        <div class="tow-left" v-animate="'queue-bottom'">
          <p class="p1">{{ elmentList[5].descList[0].descName }}</p>
          <p class="p2">{{ elmentList[5].descList[1].descName }}</p>
          <div class="tow-left-img">
            <div class="tow-left-img-row" v-for="index in 4" :key="index" :id="'elm' + elmentList[index].id"> 
              <img :src="elmentList[index].pcImg" v-if="$store.state.pageset.pcshow" />
              <img :src="elmentList[index].phoneImg" v-else />
              <p>{{ elmentList[index].descList[0].descName }}</p>
            </div>
          </div>
        </div>
        <div class="tow-right" :id="'elm' + elmentList[5].id">
          <img :src="elmentList[5].pcImg" v-if="this.$store.state.pageset.pcshow" />
          <img :src="elmentList[5].phoneImg" v-else />
          <el-button
            v-if="!this.$store.state.pageset.pcshow"
            size="small"
            class="btn-div-experience white-btn"
            plain
            @click="retention"
            >预约培训</el-button
          >
        </div>
      </div>

      <!-- PC -->
      <div v-if="this.$store.state.pageset.pcshow">
        <div class="content-tree" v-for="index in 4" :key="index" :id="'elm' + elmentList[index + 5].id">
          <div class="tow-right" v-if="index % 2 != 0">
            <img :src="elmentList[index + 5].pcImg" />
          </div>
          <div class="tow-left" v-else>
            <p style="font-size: 30px">
              {{ elmentList[index + 5].descList[0].descName }}
            </p>
            <p style="font-size: 14px; margin-top: 40px; color: rgb(97, 97, 97)">
              {{ elmentList[index + 5].descList[1].descName }}
            </p>
            <el-button
              v-animate="'queue-bottom'"
              size="small"
              class="btn-div-experience black-btn"
              plain
              @click="retention"
              >{{ elmentList[index + 5].descList[2].descName }}</el-button
            >
          </div>
          <div class="tow-left" v-animate="'queue-bottom'" v-if="index % 2 != 0">
            <p style="font-size: 30px">
              {{ elmentList[index + 5].descList[0].descName }}
            </p>
            <p style="font-size: 14px; margin-top: 40px; color: rgb(97, 97, 97)">
              {{ elmentList[index + 5].descList[1].descName }}
            </p>
            <el-button
              v-animate="'queue-bottom'"
              size="small"
              class="btn-div-experience black-btn"
              plain
              @click="retention"
              >{{ elmentList[index + 5].descList[2].descName }}</el-button
            >
          </div>
          <div class="tow-right" v-else>
            <img :src="elmentList[index + 5].pcImg" />
          </div>
        </div>
      </div>
      <!-- 手机 -->
      <div v-else>
        <div class="content-tree" v-for="index in 4" :key="index">
          <div class="tow-right">
            <img :src="elmentList[index + 5].pcImg" />
          </div>
          <div class="tow-left">
            <p style="font-size: 30px" v-animate="'queue-bottom'">
              {{ elmentList[index + 5].descList[0].descName }}
            </p>
            <p
              style="font-size: 14px; margin-top: 40px; color: rgb(97, 97, 97)"
              v-animate="'queue-bottom'"
            >
              {{ elmentList[index + 5].descList[1].descName }}
            </p>
            <el-button
              v-animate="'queue-bottom'"
              size="small"
              class="btn-div-experience black-btn"
              plain
              @click="retention"
              >{{ elmentList[index + 5].descList[2].descName }}</el-button
            >
          </div>
        </div>
      </div>

      <!-- 轮播图 -->
      <div class="content-fwo">
        <div class="mySwiper img-swiper">
          <div class="swiper-wrapper screen-swiper">
            <div class="swiper-slide" v-for="index in 6" :key="index" :id="'elm' + elmentList[index + 9].id">
              <div class="swiper-slide-p">
                <p class="p2" v-animate="'queue-bottom'">
                  {{ elmentList[index + 9].descList[1].descName }}
                </p>
                <p class="p3" v-animate="'queue-bottom'">
                  {{ elmentList[index + 9].descList[2].descName }}
                </p>
              </div>
              <!-- 滑动切换内容 -->
              <img
                :src="elmentList[index + 9].pcImg"
                v-if="$store.state.pageset.pcshow"
              />
              <img :src="elmentList[index + 9].phoneImg" v-else />
              <p v-if="!$store.state.pageset.pcshow" class="p4">
                {{ elmentList[index + 9].descList[0].descName }}
              </p>
              <div class="swiper-pagination"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="content-five" :id="'elm' + elmentList[16].id">
        <el-button
          size="small"
          class="btn-div-experience black-btn"
          plain
          @click="servicePDF"
          >{{ elmentList[16].descList[0].descName }}
        </el-button>
      </div>
    </div>
  </div>
  <div v-else v-loading="true"></div>
</template>
<script>
import service from "./service.js";

export default service;
</script>
<style scoped rel="stylesheet/stylus" lang="scss">
@import "~@/assets/style/common.scss";
@import "service.scss";
</style>
